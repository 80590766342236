import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';

import type { TypedUseSelectorHook } from 'react-redux';

import type { TRootState, TAppDispatch } from '../redux/store';

export const useAppDispatch = () => useDispatch<TAppDispatch>();
export const useAppSelector: TypedUseSelectorHook<TRootState> = useSelector;

const DEFAULT_TITLE = 'deVaulty | Your dMPC solution';

export default function usePageTitle(title = '', resetOnUnmount = true) {
  const setTitle = useCallback((title: string) => {
    document.title = title;
  }, []);

  useEffect(() => {
    title && setTitle(title);

    return () => {
      resetOnUnmount && setTitle(DEFAULT_TITLE);
    };
  }, [resetOnUnmount, setTitle, title]);

  return {
    defaultTitle: DEFAULT_TITLE,
    title: document.title,
    setTitle,
  };
}
