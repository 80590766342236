import { useNavigate } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { Typography, Grid, Button, Checkbox, FormControlLabel, Alert, Snackbar } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { useCallback, useState } from 'react';

import { RegistrationStepsRoutes } from '../constants';

import type React from 'react';

export const SecureAccount: React.FC = () => {
  const navigate = useNavigate();

  const [isChecked, setIsChecked] = useState(false);
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const IOS_LINK = process.env.REACT_APP_IOS_APP_LINK ?? '';
  const ANDROID_LINK = process.env.REACT_APP_ANDROID_APP_LINK ?? '';

  const handleNext = () => {
    navigate(RegistrationStepsRoutes.CONNECT_ACCOUNT);
  };

  const handleCopyToClipboard = useCallback((link: string) => {
    navigator.clipboard.writeText(link).then(() => {
      setSnackbarOpen(true);
    });
  }, []);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Grid
      container
      alignItems="center"
      direction="column"
      justifyContent="center"
      sx={{ mt: 4, maxWidth: 600, margin: 'auto' }}
    >
      <Grid item xs={12}>
        <Typography gutterBottom variant="h5">
          Securing your account
        </Typography>
        <Typography gutterBottom color="#45464F" variant="subtitle2">
          Install dMPC approval app on a mobile device and follow the instructions on the app.
        </Typography>
        <Typography gutterBottom color="#45464F" variant="subtitle2">
          After you have created your personal key in dMPC approval app, click next.
        </Typography>
      </Grid>
      <Grid container item justifyContent="center" my={2} spacing={2} textAlign="center" xs={12}>
        <Grid item xs={6}>
          <Typography gutterBottom variant="subtitle1">
            iOS
          </Typography>
          <QRCode value={IOS_LINK} />
          <Button
            fullWidth
            size="small"
            startIcon={<ContentCopyIcon />}
            sx={{ textTransform: 'none', color: 'black' }}
            variant="text"
            onClick={() => {
              handleCopyToClipboard(IOS_LINK);
            }}
          >
            Copy Link
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Typography gutterBottom variant="subtitle1">
            Android
          </Typography>
          <QRCode value={ANDROID_LINK} />
          <Button
            fullWidth
            endIcon={<ContentCopyIcon />}
            size="small"
            sx={{ textTransform: 'none', color: 'black' }}
            variant="text"
            onClick={() => {
              handleCopyToClipboard(ANDROID_LINK);
            }}
          >
            Copy Link
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
          label="I have created a personal key in dMPC approval app"
        />
        <Button fullWidth disabled={!isChecked} variant="contained" onClick={handleNext}>
          Next
        </Button>
      </Grid>
      <Snackbar autoHideDuration={3000} open={isSnackbarOpen} onClose={handleCloseSnackbar}>
        <Alert severity="success" sx={{ width: '100%' }} onClose={handleCloseSnackbar}>
          Link is copied
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default SecureAccount;
