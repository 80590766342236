import { useNavigate } from 'react-router-dom';

import { Paper, Grid } from '@mui/material';

import { Descope } from '@descope/react-sdk';

import usePageTitle from '../../hooks/hooks';
import { RegistrationStepsRoutes } from '../constants';

export const RegistrationPage = () => {
  const navigate = useNavigate();

  usePageTitle('deVaulty::Registration');

  return (
    <Grid
      container
      sx={{
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <Paper sx={{ p: 3, boxShadow: 'none', maxWidth: '490px', width: '100%' }}>
        <Descope
          flowId="sign-up-or-in"
          theme="light"
          onError={(err) => {
            console.warn('Error!', err);
          }}
          onSuccess={() => {
            navigate(RegistrationStepsRoutes.SELECT_ACCOUNT_TYPE);
          }}
        />
      </Paper>
    </Grid>
  );
};
