import { useNavigate } from 'react-router-dom';
import { Typography, Paper, Grid } from '@mui/material';

import { useEffect } from 'react';

import { Routes } from '../constants';

import type React from 'react';

const LoginOnAppApproved: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate(`${Routes.APP}/?firstLogin=true`, { replace: true });
    }, 2000);
  }, [navigate]);

  return (
    <Grid
      container
      sx={{
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'left',
      }}
    >
      <Paper sx={{ p: 3, boxShadow: 'none' }}>
        <Typography
          sx={{
            margin: '16px 0',
          }}
          variant="h5"
        >
          Log in approved
        </Typography>
        <Typography
          sx={{
            margin: '16px 0 32px',
          }}
          variant="subtitle2"
        >
          Redirecting to deVaulty...
        </Typography>
      </Paper>
    </Grid>
  );
};

export default LoginOnAppApproved;
