import { createSlice, type PayloadAction } from '@reduxjs/toolkit/react';

import { api } from '../../../redux/api';

import type TRegistrationState from '../../../types/TRegistrationState';

const initialState: TRegistrationState = {
  accountToConnect: null,
};

export const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    setAccountToConnect: (state, action: PayloadAction<TRegistrationState['accountToConnect']>) => {
      state.accountToConnect = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.connectAccount.matchFulfilled, (state, action) => {
      state.accountToConnect = action.payload;
    }),
      builder.addMatcher(api.endpoints.getAccountToConnect.matchFulfilled, (state, action) => {
        state.accountToConnect = action.payload;
      });
  },
});

export const { setAccountToConnect } = registrationSlice.actions;

export default registrationSlice;
