import { useNavigate } from 'react-router-dom';
import { Button, Typography, Paper, Grid, Box, Divider, CircularProgress } from '@mui/material';

import { RegistrationStepsRoutes } from '../constants';
import { useApproveLoginOnAppMutation, useGetAccountToConnectQuery } from '../../redux/api';

import type React from 'react';

const ApproveAccountConnect: React.FC = () => {
  const navigate = useNavigate();
  const { data, isFetching } = useGetAccountToConnectQuery(null);
  const [approveLoginOnApp, { isLoading }] = useApproveLoginOnAppMutation();

  const handleApprove = async () => {
    await approveLoginOnApp(null);

    navigate(RegistrationStepsRoutes.APPROVE_LOGIN_ON_APP);
  };

  if (isFetching || !data) {
    return (
      <Grid
        container
        sx={{
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'left',
        }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid
      container
      sx={{
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'left',
      }}
    >
      <Paper sx={{ p: 3, boxShadow: 'none' }}>
        <Typography
          sx={{
            margin: '16px 0',
          }}
          variant="h5"
        >
          User found!
        </Typography>
        <Typography
          sx={{
            margin: '16px 0 32px',
          }}
          variant="subtitle2"
        >
          Please check on deVaulty that your Nickname and Key ID are as shown below.
        </Typography>
        <Grid item mb={4}>
          <Box sx={{ background: '#FAF8FF', borderRadius: 1.5, px: 2, py: 1 }}>
            <Typography sx={{ color: '#45464F', fontSize: '12px', fontWeight: 500 }}>Nickname</Typography>
            <Typography noWrap variant="body1">
              {data.nickname}
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Typography sx={{ color: '#45464F', fontSize: '12px', fontWeight: 500 }}>keyId</Typography>
            <Typography noWrap variant="body1">
              {data.keyId}
            </Typography>
          </Box>
        </Grid>
        <Grid item mt={2} xs={12}>
          <Button
            fullWidth
            disabled={isLoading}
            startIcon={isLoading ? <CircularProgress size="small" /> : undefined}
            sx={{ textTransform: 'none' }}
            variant="contained"
            onClick={handleApprove}
          >
            Approve and finish setup
          </Button>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ApproveAccountConnect;
