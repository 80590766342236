import { type PayloadAction, createSlice } from '@reduxjs/toolkit/react';

import safeJsonParse from '../utils/safeJsonParse';

export type TUser = {
  firstName: string;
  lastName: string;
  email: string;
  picture: string;
};

export type TAuthState = {
  user: TUser | null;
  token: string | null;
};

const userFromLocalStorage = localStorage.getItem('user');

const storedUser = userFromLocalStorage ? safeJsonParse<TUser>(userFromLocalStorage) : null;

const initialState: TAuthState = {
  user: storedUser ?? null,
  token: localStorage.getItem('access_token') ?? null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<TUser | null>) => {
      state.user = action.payload;
      if (action.payload) {
        localStorage.setItem('user', JSON.stringify(action.payload));
      } else {
        localStorage.removeItem('user');
      }
    },
    setToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
      if (action.payload) {
        localStorage.setItem('access_token', action.payload);
      } else {
        localStorage.removeItem('access_token');
      }
    },
  },
});

export const { setUser, setToken } = authSlice.actions;

export default authSlice.reducer;
