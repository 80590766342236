import { Box, Typography } from '@mui/material';

import { countBy, map } from 'lodash';

import type { TWallet } from '../../../redux/api';

import type React from 'react';

const UsersDetails: React.FC<{ wallet: TWallet }> = ({ wallet }) => {
  const { users = [], threshold } = wallet;

  const countsByRole = countBy(users, 'role');

  return (
    <Box
      sx={{
        px: 3,
        py: 2,
        background: 'white',
        borderRadius: 2,
        display: 'flex',
        gap: 1.5,
        flexDirection: 'column',
        minHeight: '211px',
      }}
    >
      <Typography variant="h6">Team</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flexGrow: 1 }}>
        {map(countsByRole, (count, role) => (
          <Box key={role} sx={{ display: 'flex' }}>
            <Typography sx={{ fontSize: '14px', color: '#01063C', flexGrow: 1, fontWeight: '600' }}>{role}</Typography>
            <Typography sx={{ fontSize: '14px', color: '#01063C' }}>{count}</Typography>
          </Box>
        ))}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
        <Typography sx={{ fontSize: '14px', color: '#01063C' }}>{`Sign Threshold ${threshold}`}</Typography>
        <Box sx={{ textTransform: 'none', ml: 'auto', fontSize: '14px', color: '#4050FB' }}>Edit threshold</Box>
      </Box>
    </Box>
  );
};

export default UsersDetails;
