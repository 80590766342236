import { GridActionsCellItem } from '@mui/x-data-grid';

import Chip from '@mui/material/Chip';

import Box from '@mui/material/Box';

import { Typography } from '@mui/material';

import { format } from 'date-fns';

import type { TApproval } from '../../../redux/api';

import type { GridColDef } from '@mui/x-data-grid/models';

export const DATE: GridColDef<TApproval> = {
  field: 'date',
  minWidth: 300,
  headerName: 'Date',
  disableColumnMenu: true,
  valueGetter: (value) => {
    return format(value * 1000, 'E MMM dd yyyy HH:MM:SS');
  },
};

export const ACTION: GridColDef<TApproval> = {
  field: 'action',
  minWidth: 300,
  headerName: 'Action',
  disableColumnMenu: true,
};

export const INITIATOR: GridColDef<TApproval> = {
  field: 'initiator',
  minWidth: 300,
  headerName: 'Initiator',
  disableColumnMenu: true,
  flex: 1,
  renderCell: ({ value }) => (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography sx={{ fontSize: '14px', fontWeight: '500', color: '#01063C' }}>{value.name}</Typography>
      <Typography sx={{ fontSize: '11px', fontWeight: '500', color: '#949494' }}>{value.organization}</Typography>
    </Box>
  ),
};
export const STATUS: GridColDef<TApproval> = {
  field: 'status',
  minWidth: 300,
  headerName: 'Status',
  disableColumnMenu: true,
  renderCell: ({ value }) => <Chip label={String(value)} />,
};

export const ACTIONS: GridColDef<TApproval> = {
  field: 'actions',
  type: 'actions',
  disableColumnMenu: true,
  sortable: false,
  getActions: () => [
    <GridActionsCellItem key="details" showInMenu label="Details" sx={{ minWidth: 220 }} />,
    <GridActionsCellItem key="nudge" showInMenu label="Nudge approvers" />,
  ],
};

export default [DATE, ACTION, INITIATOR, STATUS, ACTIONS];
