import {
  DialogTitle,
  DialogContent,
  LinearProgress,
  DialogContentText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Button,
} from '@mui/material';

import type { TFormElements } from '../CreateWalletFlow';

import type { TWalletData } from '../../../../redux/api';

import type TCreateWalletStepsProps from '../../../../types/TCreateWalletStepsProps';
import type TFormElement from '../../../../types/TFormElement';

import type React from 'react';

const WalletThreshold: React.FC<TCreateWalletStepsProps & { createWallet: (data: TWalletData) => void }> = ({
  onClose,
  setWalletData,
  walletData,
  createWallet,
}) => {
  return (
    <form
      onSubmit={(event: React.FormEvent<TFormElement<TFormElements>>) => {
        event.preventDefault();

        const { threshold } = event.currentTarget.elements;

        setWalletData?.((data) => {
          const updatedData = { ...data, threshold: threshold.value };

          createWallet(updatedData);

          return updatedData;
        });
      }}
    >
      <DialogTitle color="primary" id="alert-dialog-title">
        Create wallet
      </DialogTitle>
      <DialogContent>
        <LinearProgress value={95} variant="determinate" />
        <DialogContentText fontSize="16px" id="alert-dialog-description" my={4}>
          Lorem ipsum dolor sit amet consectetur. Sapien enim montes et dolor.
        </DialogContentText>
        <FormControl sx={{ mt: 4 }}>
          <InputLabel id="demo-simple-select-label">Threshold</InputLabel>
          <Select
            defaultValue={walletData?.threshold}
            id="demo-simple-select"
            label="Threshold"
            labelId="demo-simple-select-label"
            name="threshold"
          >
            <MenuItem value="1of6">1 of 6</MenuItem>
            <MenuItem value="2of6">2 of 6</MenuItem>
            <MenuItem value="3of6">3 of 6</MenuItem>
            <MenuItem value="4of6">4 of 6</MenuItem>
            <MenuItem value="5of6">5 of 6</MenuItem>
            <MenuItem value="6of6">6 of 6</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button sx={{ ml: 0, mr: 'auto', textTransform: 'none' }} variant="text" onClick={onClose}>
          Close
        </Button>
        <Button autoFocus sx={{ textTransform: 'none' }} type="submit" variant="contained">
          Next step
        </Button>
      </DialogActions>
    </form>
  );
};

export default WalletThreshold;
