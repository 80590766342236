import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, TextField, Typography, Paper, Grid, CircularProgress } from '@mui/material';

import { useSession, useUser } from '@descope/react-sdk';

import { useCreateOrganizationMutation } from '../../redux/api';

import { RegistrationStepsRoutes } from '../constants';

import type React from 'react';

import type TFormElement from '../../types/TFormElement';

type TFormElements = {
  organizationName: HTMLInputElement;
};

const CreateOrganization: React.FC = () => {
  const { user } = useUser();
  const { isAuthenticated } = useSession();
  const navigate = useNavigate();

  const [error, setError] = useState('');

  const [createOrganization, { isLoading }] = useCreateOrganizationMutation();

  const handleCreateOrganization = async (event: React.FormEvent<TFormElement<TFormElements>>) => {
    event.preventDefault();

    const {
      organizationName: { value },
    } = event.currentTarget.elements;

    if (!value) {
      return;
    }

    if (!Boolean(value.match(/[a-zA-Z0-9\_]{4,}/))) {
      setError('min 4 letters, numbers, underscore');

      return;
    }

    if (!isAuthenticated) {
      setError('Not authenticated');

      return;
    }

    setError('');

    const result = createOrganization({ loginId: user.loginIds[0], organizationName: value });

    if ('error' in result && typeof result.error === 'string') {
      setError(result.error);

      return;
    }

    navigate(RegistrationStepsRoutes.SECURE_ACCOUNT);
  };

  return (
    <Grid
      container
      sx={{
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <Paper sx={{ p: 3, boxShadow: 'none' }}>
        <Typography
          sx={{
            margin: '16px 0 32px',
          }}
          variant="h5"
        >
          Create organization.
        </Typography>
        <Typography
          sx={{
            margin: '16px 0 32px',
          }}
          variant="subtitle1"
        >
          Create organization name that will be used by the team.
        </Typography>
        <form onSubmit={handleCreateOrganization}>
          <Grid item>
            <TextField
              fullWidth
              disabled={isLoading}
              error={!!error}
              helperText={error}
              id="email"
              label="Enter organization name"
              name="organizationName"
              size="small"
              sx={{
                width: '100%',
                marginBottom: '8px',
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <Button startIcon={isLoading ? <CircularProgress size="1em" /> : null} type="submit" variant="contained">
              {isLoading ? 'Creating...' : 'Next'}
            </Button>
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
};

export default CreateOrganization;
