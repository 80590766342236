import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, TextField, Typography, Paper, Grid, CircularProgress } from '@mui/material';

import { RegistrationStepsRoutes } from '../constants';

import { useConnectAccountMutation } from '../../redux/api';

import type React from 'react';

import type TFormElement from '../../types/TFormElement';

type TFormFields = {
  nicknameOrKeyId: HTMLInputElement;
};

const ConnectAccount: React.FC = () => {
  const navigate = useNavigate();

  const [fieldError, setFieldError] = useState('');
  const [connectAccount, { isLoading }] = useConnectAccountMutation();

  const handleSubmit = async (event: React.FormEvent<TFormElement<TFormFields>>) => {
    event.preventDefault();

    const {
      nicknameOrKeyId: { value },
    } = event.currentTarget.elements;

    if (!value) {
      return;
    }

    if (!Boolean(value.match(/[a-zA-Z0-9\_]{4,}/))) {
      setFieldError('min 4 letters, numbers, underscore');

      return;
    }

    setFieldError('');

    const result = await connectAccount({ nicknameOrKeyId: value });

    if ('error' in result && typeof result.error === 'string') {
      setFieldError(result.error as string);

      return;
    }

    navigate(RegistrationStepsRoutes.APPROVE_ACCOUNT_CONNECT);
  };

  return (
    <Grid
      container
      sx={{
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'left',
      }}
    >
      <Paper sx={{ p: 3, boxShadow: 'none' }}>
        <Typography
          sx={{
            margin: '16px 0',
          }}
          variant="h5"
        >
          Connect your new account with your personal key
        </Typography>
        <Typography
          sx={{
            margin: '16px 0 32px',
          }}
          variant="subtitle2"
        >
          Type your Nickname or Key ID.
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid item>
            <TextField
              fullWidth
              disabled={isLoading}
              error={!!fieldError}
              helperText={fieldError}
              id="nicknameOrKeyId"
              label="Nickname or Key ID"
              name="nicknameOrKeyId"
              size="small"
              sx={{
                width: '100%',
                marginBottom: '8px',
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item mt={2} xs={12}>
            <Button
              fullWidth
              disabled={isLoading}
              startIcon={isLoading ? <CircularProgress size="1em" /> : null}
              sx={{ textTransform: 'none' }}
              type="submit"
              variant="contained"
            >
              {isLoading ? 'Connecting...' : 'Next'}
            </Button>
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
};

export default ConnectAccount;
