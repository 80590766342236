import {
  DialogTitle,
  DialogContent,
  LinearProgress,
  DialogContentText,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Button,
} from '@mui/material';

import { STEP_NUMBERS, type TFormElements } from '../CreateWalletFlow';

import type TCreateWalletStepsProps from '../../../../types/TCreateWalletStepsProps';

import type React from 'react';

import type TFormElement from '../../../../types/TFormElement';

const WalletNameStep: React.FC<TCreateWalletStepsProps> = ({ onNext, onClose, walletData, setWalletData }) => {
  return (
    <form
      onSubmit={(event: React.FormEvent<TFormElement<TFormElements>>) => {
        event.preventDefault();

        const { walletName, type } = event.currentTarget.elements;

        setWalletData?.((data) => ({ ...data, walletName: walletName.value, type: type.value }));

        onNext(STEP_NUMBERS.manageAccess);
      }}
    >
      <DialogTitle color="primary" id="alert-dialog-title">
        Create wallet
      </DialogTitle>
      <DialogContent>
        <LinearProgress value={5} variant="determinate" />
        <DialogContentText fontSize="16px" id="alert-dialog-description" my={4}>
          Lorem ipsum dolor sit amet consectetur. Vitae mattis suspendisse nunc a quam consectetur semper convallis
          phasellus.
        </DialogContentText>
        <TextField
          autoFocus
          fullWidth
          required
          defaultValue={walletData?.walletName}
          id="walletName"
          label="Name your wallet"
          name="walletName"
          placeholder="Type a name for your new wallet"
          type="text"
          variant="outlined"
        />
        <FormControl fullWidth sx={{ mt: 4 }}>
          <InputLabel id="demo-simple-select-label">Type of wallet</InputLabel>
          <Select
            defaultValue={walletData?.type}
            id="demo-simple-select"
            label="Type of wallet"
            labelId="demo-simple-select-label"
            name="type"
          >
            <MenuItem value="mpc">MPC Wallet</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button sx={{ ml: 0, mr: 'auto', textTransform: 'none' }} variant="text" onClick={onClose}>
          Close
        </Button>
        <Button autoFocus sx={{ textTransform: 'none' }} type="submit" variant="contained">
          Next step
        </Button>
      </DialogActions>
    </form>
  );
};

export default WalletNameStep;
