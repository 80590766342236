import { Typography, styled } from '@mui/material';

import type React from 'react';

const StyledLoadingPage = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100dvh',
  backgroundColor: theme.palette.background.default,
}));

const LoadingPage: React.FC = () => {
  return (
    <StyledLoadingPage>
      <Typography color="textPrimary" variant="subtitle1">
        Loading...
      </Typography>
    </StyledLoadingPage>
  );
};

export default LoadingPage;
