import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

import { STEP_NUMBERS } from '../CreateWalletFlow';

import type TCreateWalletStepProps from '../../../../types/TCreateWalletStepsProps';

import type React from 'react';

const WelcomeTo: React.FC<TCreateWalletStepProps> = ({ onNext, onClose }) => {
  return (
    <>
      <DialogTitle color="primary" id="alert-dialog-title">
        Welcome to deVaulty
      </DialogTitle>
      <DialogContent>
        <DialogContentText fontSize="16px" id="alert-dialog-description">
          Lorem ipsum dolor sit amet consectetur. Vitae mattis suspendisse nunc a quam consectetur semper convallis
          phasellus. Condimentum at amet facilisis pharetra facilisis amet vitae. Rhoncus vivamus nec nulla arcu vitae
          tincidunt lacinia aliquet. In amet dolor risus venenatis porttitor adipiscing vel scelerisque.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          autoFocus
          sx={{ ml: 0, mr: 'auto', textTransform: 'none' }}
          variant="contained"
          onClick={() => {
            onNext(STEP_NUMBERS.walletName);
          }}
        >
          Create wallet
        </Button>
        <Button sx={{ textTransform: 'none' }} variant="text" onClick={onClose}>
          Skip for now
        </Button>
      </DialogActions>
    </>
  );
};

export default WelcomeTo;
