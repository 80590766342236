import Box from '@mui/material/Box';

import { Chip, IconButton, Typography } from '@mui/material';

import { CloseRounded } from '@mui/icons-material';

import FoundUserItem from './FoundUserItem';

import UserRoleSelect from './UserRoleSelect';

import type { TWalletCreateUser } from './ManageAccessStep';

import type React from 'react';

export type TUserEntry = {
  user: TWalletCreateUser;
  onDelete: (user: TWalletCreateUser) => void;
  onUserEdit: (user: TWalletCreateUser) => void;
  isEditable?: boolean;
  isDeletable?: boolean;
  isInvited?: boolean;
};

const UserEntry: React.FC<TUserEntry> = ({
  user,
  isEditable = true,
  isDeletable = true,
  onDelete,
  onUserEdit,
  isInvited,
}) => {
  const handleRoleChange = (role: string) => {
    onUserEdit({ ...user, role });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, p: 2, alignItems: 'center' }}>
      <FoundUserItem email={user.email} name={user.name} />
      {isInvited ? <Chip label="Pending invite" /> : null}
      {isEditable ? (
        <UserRoleSelect onChange={handleRoleChange} />
      ) : (
        <Typography fontSize="14px" variant="body1">
          {user.role}
        </Typography>
      )}
      {isDeletable ? (
        <IconButton
          size="small"
          sx={{ width: 32, height: 32 }}
          onClick={() => {
            onDelete(user);
          }}
        >
          <CloseRounded />
        </IconButton>
      ) : null}
    </Box>
  );
};

export default UserEntry;
