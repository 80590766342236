export const Routes = {
  LOGIN: '/login',
  SIGN_UP: '/signup',
  APP: '/app',
  API: '/api',
  WALLETS: '/app/wallets',
} as const;

export const RegistrationStepsRoutes = {
  SELECT_ACCOUNT_TYPE: `${Routes.SIGN_UP}/select-account-type`,
  CREATE_ORGANIZATION: `${Routes.SIGN_UP}/create-organization`,
  SECURE_ACCOUNT: `${Routes.SIGN_UP}/secure-account`,
  CONNECT_ACCOUNT: `${Routes.SIGN_UP}/connect-account`,
  APPROVE_ACCOUNT_CONNECT: `${Routes.SIGN_UP}/approve-connect`,
  APPROVE_LOGIN_ON_APP: `${Routes.SIGN_UP}/approve-login-on-app`,
  LOGIN_ON_APP_REJECTED: `${Routes.SIGN_UP}/login-on-app-rejected`,
  LOGIN_ON_APP_APPROVED: `${Routes.SIGN_UP}/login-on-app-approved`,
};

export const RegistrationEndpoints = {
  SELECT_ACCOUNT_TYPE: `${Routes.SIGN_UP}/selectAccountType`,
  CREATE_ORGANIZATION: `${Routes.SIGN_UP}/createOrganization`,
  CONNECT_ACCOUNT: `${Routes.SIGN_UP}/connectAccount`,
  GET_ACCOUNT_TO_CONNECT: `${Routes.SIGN_UP}/getAccountToConnect`,
  APPROVE_LOGIN_ON_APP: `${Routes.SIGN_UP}/approveLoginOnApp`,
  CREATE_WALLET: `${Routes.API}/createWallet`,
  WALLETS_LIST: `${Routes.API}/wallets`,
  USER_ROLES: `${Routes.API}/getUserRoles`,
  SEARCH_USERS: `${Routes.API}/searchUsers`,
  APPROVALS: `${Routes.API}/approvals/:walletId`,
} as const;
