import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ThemeProvider, CssBaseline, Box } from '@mui/material';
import { createTheme } from '@mui/material/styles';

import { RegistrationPage } from './components/Registration/RegistrationPage';
import ErrorBoundary from './components/ErrorBoundary';
import { Routes as AppRoutes, RegistrationStepsRoutes } from './components/constants';
import LoginPage from './components/Login/LoginPage';
import SelectAccountType from './components/Registration/SelectAccountType';
import CreateOrganization from './components/Registration/CreateOrganization';
import SecureAccount from './components/Registration/SecureAccount';
import ConnectAccount from './components/Registration/ConnectAccount';
import ApproveAccountConnect from './components/Registration/ApproveAccountConnect';
import ApproveLoginOnApp from './components/Registration/ApproveLoginOnApp';
import LoginOnAppRejected from './components/Registration/LoginOnAppRejected';
import LoginOnAppApproved from './components/Registration/LoginOnAppApproved';
import MainApp from './app/MainApp';
import Wallets from './app/Wallets/Wallets';
import { WalletCreateFlowContextProvider } from './context/WalletCreateFlowContext';

// Creating a basic theme for Material-UI
const theme = createTheme({
  // You can customize your theme here
});

// only lazy load and render dev tools if in ENABLE_DEV_TOOLS is true
const DevTools =
  process.env.REACT_APP_DEV_TOOLS === 'true'
    ? React.lazy(async () => import('./components/DevTools/DevTools'))
    : ({ children }: { children: JSX.Element }) => children;

const App: React.FC = () => {
  return (
    <Box sx={{ width: '100%', height: '100dvh', overflow: 'auto' }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <ErrorBoundary>
            <WalletCreateFlowContextProvider>
              <DevTools>
                <Routes>
                  <Route path={AppRoutes.SIGN_UP}>
                    <Route element={<SelectAccountType />} path={RegistrationStepsRoutes.SELECT_ACCOUNT_TYPE} />
                    <Route element={<CreateOrganization />} path={RegistrationStepsRoutes.CREATE_ORGANIZATION} />
                    <Route element={<SecureAccount />} path={RegistrationStepsRoutes.SECURE_ACCOUNT} />
                    <Route element={<ConnectAccount />} path={RegistrationStepsRoutes.CONNECT_ACCOUNT} />
                    <Route element={<ApproveAccountConnect />} path={RegistrationStepsRoutes.APPROVE_ACCOUNT_CONNECT} />
                    <Route element={<ApproveLoginOnApp />} path={RegistrationStepsRoutes.APPROVE_LOGIN_ON_APP} />
                    <Route element={<LoginOnAppRejected />} path={RegistrationStepsRoutes.LOGIN_ON_APP_REJECTED} />
                    <Route element={<LoginOnAppApproved />} path={RegistrationStepsRoutes.LOGIN_ON_APP_APPROVED} />
                    <Route index element={<RegistrationPage />} />
                  </Route>
                  <Route element={<MainApp />} path={`${AppRoutes.APP}/*`}>
                    <Route element={<Wallets />} path="wallets/:walletId?" />
                  </Route>
                  <Route element={<Navigate to={`${AppRoutes.APP}/wallets`} />} path={AppRoutes.APP}></Route>
                  <Route index element={<LoginPage />} path={`${AppRoutes.LOGIN}?`} />
                </Routes>
              </DevTools>
            </WalletCreateFlowContextProvider>
          </ErrorBoundary>
        </BrowserRouter>
      </ThemeProvider>
    </Box>
  );
};

export default App;
