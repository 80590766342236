import {
  DialogTitle,
  DialogContent,
  LinearProgress,
  DialogContentText,
  DialogActions,
  Button,
  Box,
} from '@mui/material';

import { useUser } from '@descope/react-sdk';

import { useCallback, useEffect, useState } from 'react';

import { STEP_NUMBERS } from '../../CreateWalletFlow';

import UserEntry from './UserEntry';

import SearchUsersField from './SearchUsersField';

import type React from 'react';

import type TCreateWalletStepsProps from '../../../../../types/TCreateWalletStepsProps';

export type TWalletCreateUser = {
  name: string;
  email: string;
  role: string;
  invited?: boolean;
};

const ManageAccessStep: React.FC<TCreateWalletStepsProps> = ({ onClose, onNext, setWalletData }) => {
  const [users, setUsers] = useState<TWalletCreateUser[]>([]);

  const { user: me } = useUser();

  const handleUserDelete = useCallback((user: TWalletCreateUser) => {
    setUsers((users) => users.filter(({ email }) => user.email !== email));
  }, []);

  const handleUserEdit = useCallback((user: TWalletCreateUser) => {
    setUsers((users) => users.map((item) => (user.email !== item.email ? user : item)));
  }, []);

  const handleAddUser = useCallback((user: TWalletCreateUser) => {
    setUsers((users) => [...users, user]);
  }, []);

  useEffect(() => {
    if (!users.length) {
      setUsers([{ name: String(me.name), email: String(me.email), role: 'Owner' }]);
    }
  }, [me.email, me.name, users.length]);

  return (
    <>
      <DialogTitle color="primary" id="alert-dialog-title">
        Create wallet
      </DialogTitle>
      <DialogContent>
        <LinearProgress value={50} variant="determinate" />
        <DialogContentText fontSize="16px" id="alert-dialog-description" my={4}>
          Manage who can access the wallet and at what level.
        </DialogContentText>
        <SearchUsersField onAddUser={handleAddUser} />
        <Box mt={2}>
          {users.map((user) => (
            <UserEntry
              key={user.email}
              isDeletable={user.email !== me.email || users.length > 1}
              isEditable={user.email !== me.email || users.length > 1}
              isInvited={user.invited}
              user={user}
              onDelete={handleUserDelete}
              onUserEdit={handleUserEdit}
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button sx={{ ml: 0, mr: 'auto', textTransform: 'none' }} variant="text" onClick={onClose}>
          Close
        </Button>
        <Button
          autoFocus
          sx={{ textTransform: 'none' }}
          variant="contained"
          onClick={() => {
            setWalletData?.((data) => ({ ...data, users }));
            onNext(STEP_NUMBERS.threshold);
          }}
        >
          Next step
        </Button>
      </DialogActions>
    </>
  );
};

export default ManageAccessStep;
