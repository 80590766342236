import Box from '@mui/material/Box';

import { DataGrid } from '@mui/x-data-grid';

import Typography from '@mui/material/Typography';

import columns from './columns';

import { useGetApprovalsQuery } from '../../../redux/api';

import type { BoxProps } from '@mui/material/Box';

import type React from 'react';

export type TApprovalsProps = {
  walletId: string;
} & Partial<BoxProps>;

const Approvals: React.FC<TApprovalsProps> = ({ walletId, sx, ...props }) => {
  const { data = [], isFetching } = useGetApprovalsQuery(
    { walletId },
    { skip: !walletId, refetchOnMountOrArgChange: true },
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 3,
        width: '100%',
        height: '100%',
        maxHeight: 'calc(100dvh - 57px)',
        borderRadius: 2,
        flexGrow: 1,
        backgroundColor: 'background.paper',
        ...sx,
      }}
      {...props}
    >
      <Typography mb={1} px={1} variant="h6">
        Approvals
      </Typography>

      <DataGrid
        hideFooter
        columns={columns}
        getRowHeight={() => 'auto'}
        loading={isFetching}
        rows={data}
        sx={{
          border: 'none',
          height: 'auto',
          minHeight: '400px',
          '.MuiDataGrid-cell': {
            p: 2,
            alignItems: 'center',
          },
        }}
      />
    </Box>
  );
};

export default Approvals;
