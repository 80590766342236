import { Box, Typography } from '@mui/material';

import type React from 'react';

const FoundUserItem: React.FC<{ name: string; email: string }> = ({ name, email }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <Typography noWrap fontSize="16px" variant="body1">
        {name}
      </Typography>
      <Typography noWrap fontSize="12px" variant="body2">
        {email}
      </Typography>
    </Box>
  );
};

export default FoundUserItem;
