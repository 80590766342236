import React, { type PropsWithChildren, useState } from 'react';

type TWalletCreateFlowContext = {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
};

export const WalletCreateFlowContext = React.createContext<TWalletCreateFlowContext | null>(null);

export const WalletCreateFlowContextProvider = ({ children }: PropsWithChildren) => {
  const [isOpen, setOpen] = useState(false);

  return <WalletCreateFlowContext.Provider value={{ isOpen, setOpen }}>{children}</WalletCreateFlowContext.Provider>;
};

const useWalletCreateFlowContext = () => {
  const context = React.useContext(WalletCreateFlowContext);

  if (!context) {
    throw new Error('useWalletCreateFlowContext must be used within WalletCreateFlowContextProvider');
  }

  return context;
};

export default useWalletCreateFlowContext;
