import { useNavigate } from 'react-router-dom';

import { Typography, Card, CardActionArea, CardContent, Grid, Box, FormHelperText } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';

import { useMemo } from 'react';

import { useSession, useUser } from '@descope/react-sdk';

import { RegistrationStepsRoutes } from '../constants';

import { useSelectAccountTypeMutation } from '../../redux/api';

import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import type React from 'react';

export const SelectAccountType: React.FC = () => {
  const { user } = useUser();
  const { isAuthenticated } = useSession();
  const navigate = useNavigate();

  const [selectAccountType, { error }] = useSelectAccountTypeMutation();

  const errorMessage = useMemo(() => {
    if (!isAuthenticated) {
      return 'Unauthenticated';
    }

    if (!error) {
      return '';
    }

    const { data = {} } = error as FetchBaseQueryError;
    const { error: msg } = data as { error?: string };

    return msg;
  }, [error, isAuthenticated]);

  const handleSelectAccountType = async (type: 'personal' | 'organization') => {
    if (!isAuthenticated) {
      return;
    }

    const res = await selectAccountType({ type, loginId: user.loginIds[0] });

    if ('error' in res) {
      return;
    }

    if (type === 'personal') {
      navigate(RegistrationStepsRoutes.SECURE_ACCOUNT);

      return;
    }

    navigate(RegistrationStepsRoutes.CREATE_ORGANIZATION); // Replace with the next step in your registration flow
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        p: 2,
        mt: 4,
        maxWidth: 600,
        margin: 'auto',
        alignContent: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Grid item mb={4} xs={12}>
        <Typography gutterBottom textAlign="center" variant="h4">
          Select the type of account
        </Typography>
        <Typography gutterBottom color="grey" textAlign="center" variant="subtitle1">
          Select the account type that fits your needs.
        </Typography>
      </Grid>
      <Grid container sx={{ display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'center' }}>
        <Grid item md={8}>
          <Card
            variant="outlined"
            onClick={() => {
              handleSelectAccountType('personal');
            }}
          >
            <CardActionArea>
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    flexDirection: 'row',
                    mb: 2,
                    gap: 2,
                  }}
                >
                  <PersonIcon fontSize="large" />
                  <Typography variant="h5">Personal</Typography>
                </Box>
                <Typography color="grey" sx={{ fontSize: 14 }} variant="body2">
                  For individuals that want secure wallets for storing their crypto assets and secure interaction in the
                  Web3 space.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item md={8}>
          <Card
            variant="outlined"
            onClick={() => {
              handleSelectAccountType('organization');
            }}
          >
            <CardActionArea>
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    flexDirection: 'row',
                    mb: 2,
                    gap: 2,
                  }}
                >
                  <BusinessIcon fontSize="large" />
                  <Typography variant="h5">Organization</Typography>
                </Box>
                <Typography color="grey" sx={{ fontSize: 14 }} variant="body2">
                  For teams and business that need high secure Web3 wallets, that are working together as a team and
                  want to have multiple approvers.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
      {errorMessage ? (
        <Grid item>
          <FormHelperText error>{errorMessage}</FormHelperText>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default SelectAccountType;
