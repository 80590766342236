import { MenuItem, Select, type SelectProps } from '@mui/material';

import { useGetUserRolesQuery } from '../../../../../redux/api';

import type React from 'react';

export type TUserRoleSelectProps = {
  onChange: (role: string) => void;
} & Partial<Omit<SelectProps, 'onChange'>>;

const UserRoleSelect: React.FC<TUserRoleSelectProps> = ({ onChange, defaultValue = 'Viewer', ...menuProps }) => {
  const { isLoading, data } = useGetUserRolesQuery(null);

  const roles = data?.length ? (
    data.map(({ name }) => (
      <MenuItem key={name} value={name}>
        {name}
      </MenuItem>
    ))
  ) : (
    <MenuItem disabled value="">
      Loading
    </MenuItem>
  );

  return (
    <Select
      defaultValue={defaultValue}
      id="demo-simple-select"
      labelId="demo-simple-select-label"
      margin="dense"
      sx={{ fontSize: '14px' }}
      {...menuProps}
      disabled={isLoading}
    >
      {roles}
    </Select>
  );
};

export default UserRoleSelect;
