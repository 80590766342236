import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getSessionToken } from '@descope/react-sdk';

import safeJsonParse from '../utils/safeJsonParse';
import { RegistrationEndpoints } from '../components/constants';

import type { UserResponse } from '@descope/core-js-sdk';

import type { TAccountToConnect } from '../types/TRegistrationState';

export type TMessage = {
  status: EApproveLoginOnAppStatus;
};

type TWebSocketMessage = {
  type: string;
  status: EApproveLoginOnAppStatus;
};

export type TAccountTypePayload = {
  type: 'personal' | 'organization';
  loginId: string;
};

export type TConnectAccountPayload = {
  nicknameOrKeyId: string;
};

export enum EApproveLoginOnAppStatus {
  Uninitialized = 'uninitialized',
  Initialize = 'initialize',
  Approved = 'approved',
  Rejected = 'rejected',
}

export type TWalletData = {
  walletName: string;
  type: string;
  threshold: string;
  users?: Array<{
    email: string;
    role: string;
    invited?: boolean;
  }>;
};

export type TWalletDataPayload = {
  walletData: TWalletData;
};

export type TWallet = TWalletData & {
  walletId: string;
  walletAddress: string;
};

export type TUserRole = {
  name: string;
  description?: string;
  permissionNames: string[];
  createdTime: number;
  tenantId?: string;
};

export type TSearchUsersPayload = {
  email?: string;
};

export type TApproval = {
  id: string;
  date: number;
  action: string;
  initiator: {
    name: string;
    organiation: string;
  };
  status: string;
};

// Helper function to check if an object is a WebSocketMessage
const isWebSocketMessage = (obj?: object): obj is TWebSocketMessage => {
  return !!obj && 'type' in obj;
};

export const api = createApi({
  tagTypes: ['WALLETS', 'USER_ROLES', 'FOUND_USERS', 'APPROVALS'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      const sessionToken = getSessionToken();

      headers.set('Accept', 'application/json');
      headers.set('Authorization', `Bearer ${sessionToken}`);

      return headers;
    },
  }),
  endpoints: (build) => ({
    getAccountToConnect: build.query<TAccountToConnect, null>({
      query: () => RegistrationEndpoints.GET_ACCOUNT_TO_CONNECT,
    }),
    getWallets: build.query<TWallet[], null>({
      query: () => RegistrationEndpoints.WALLETS_LIST,
      providesTags: ['WALLETS'],
    }),
    getUserRoles: build.query<TUserRole[], null>({
      query: () => RegistrationEndpoints.USER_ROLES,
      providesTags: ['USER_ROLES'],
    }),
    getApprovals: build.query<TApproval[], { walletId: string }>({
      query: ({ walletId = '' }) => RegistrationEndpoints.APPROVALS.replace(':walletId', walletId),
      providesTags: ['APPROVALS'],
    }),
    searchUsers: build.query<UserResponse[], TSearchUsersPayload>({
      query: (params) => ({
        url: RegistrationEndpoints.SEARCH_USERS,
        params,
      }),
      providesTags: ['FOUND_USERS'],
    }),
    connectAccount: build.mutation<TAccountToConnect, TConnectAccountPayload>({
      query: (body) => ({
        url: RegistrationEndpoints.CONNECT_ACCOUNT,
        method: 'POST',
        body,
      }),
    }),
    createOrganization: build.mutation<{ status: number }, { loginId: string; organizationName: string }>({
      query: (body) => ({
        url: RegistrationEndpoints.CREATE_ORGANIZATION,
        method: 'POST',
        body,
      }),
    }),
    selectAccountType: build.mutation<null, TAccountTypePayload>({
      query: (body) => ({
        url: RegistrationEndpoints.SELECT_ACCOUNT_TYPE,
        method: 'POST',
        body,
      }),
    }),
    createWallet: build.mutation<TWallet, TWalletDataPayload>({
      query: (body) => ({
        url: RegistrationEndpoints.CREATE_WALLET,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['WALLETS'],
    }),
    approveLoginOnApp: build.mutation<
      { status: EApproveLoginOnAppStatus },
      null | { status: EApproveLoginOnAppStatus }
    >({
      query: (body) => ({
        url: RegistrationEndpoints.APPROVE_LOGIN_ON_APP,
        method: 'POST',
        body: body ?? {},
      }),
    }),
    getApproveLoginOnAppStatus: build.query<{ status: string }, string>({
      queryFn: () => ({ data: { status: EApproveLoginOnAppStatus.Initialize } }),
      async onCacheEntryAdded(arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
        const socketAddress = process.env.REACT_APP_APPROVE_LOGIN_ON_APP_SOCKET ?? '';
        // create a websocket connection when the cache subscription starts
        const ws = new WebSocket(socketAddress);

        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;

          // when data is received from the socket connection to the server,
          // if it is a message and for the appropriate channel,
          // update our query result with the received message
          const listener = (event: MessageEvent<string>) => {
            const data = safeJsonParse<TWebSocketMessage>(event.data);

            if (!isWebSocketMessage(data) || data.type !== arg) {
              return;
            }

            updateCachedData((draft) => {
              draft.status = data.status;
            });
          };

          ws.addEventListener('message', listener);
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }

        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved;
        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
        ws.close();
      },
    }),
  }),
});

export const {
  useGetApproveLoginOnAppStatusQuery,
  useGetAccountToConnectQuery,
  useConnectAccountMutation,
  useApproveLoginOnAppMutation,
  useSelectAccountTypeMutation,
  useCreateOrganizationMutation,
  useCreateWalletMutation,
  useGetWalletsQuery,
  useGetUserRolesQuery,
  useSearchUsersQuery,
  useGetApprovalsQuery,
} = api;
