import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { AuthProvider } from '@descope/react-sdk';

import { store } from './redux/store';
import App from './App';
import './index.css';

const container = document.getElementById('root')!;
const root = createRoot(container);

if (process.env.REACT_APP_ENABLE_MSW === 'true') {
  (async () => {
    const { initMsw } = await import('./mocks/browser');

    try {
      await initMsw();
    } catch (error) {
      console.error(error);
    }
  })();
}

root.render(
  <React.StrictMode>
    <AuthProvider projectId={process.env.REACT_APP_DESCOPE_PROJECT_ID ?? ''}>
      <Provider store={store}>
        <App />
      </Provider>
    </AuthProvider>
  </React.StrictMode>,
);
