import { Paper, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Descope } from '@descope/react-sdk';

import { Routes } from '../constants';

import usePageTitle from '../../hooks/hooks';

import type React from 'react';

const LoginPage: React.FC = () => {
  usePageTitle('deVaulty::Log in');

  const navigate = useNavigate();

  return (
    <Grid
      container
      sx={{
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <Paper sx={{ p: 3, boxShadow: 'none', maxWidth: '490px', width: '100%' }}>
        <Descope
          flowId="sign-in"
          theme="light"
          onError={(err) => {
            console.warn('Error!', err);
          }}
          onSuccess={() => {
            navigate(Routes.APP);
          }}
        />
      </Paper>
    </Grid>
  );
};

export default LoginPage;
