import * as React from 'react';
import { styled, useTheme, type Theme, type CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AccountBalance, DoneAll, Gavel, GridView, Logout } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';

import { useUser, useDescope } from '@descope/react-sdk';

import Avatar from '@mui/material/Avatar';

import { Routes } from '../../components/constants';
import CreateWalletFlow from './CreateWalletFlow/CreateWalletFlow';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const menuItems = [
  { title: 'My wallets', icon: <AccountBalance />, link: 'wallets' },
  { title: 'Approvals', icon: <DoneAll />, link: 'approvals' },
  { title: 'Governance', icon: <Gavel />, link: 'governance' },
  { title: 'Dapps', icon: <GridView />, link: 'dapps' },
];

export default function Layout({ children }: React.PropsWithChildren) {
  const theme = useTheme();
  const navigate = useNavigate();
  const params = useParams();

  const { user } = useUser();
  const { logout } = useDescope();

  const menuItem = params['*'] as string;

  const [isOpen, setOpen] = React.useState(true);

  const iconSize = isOpen ? { width: 48, height: 48 } : { width: 32, height: 32 };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', background: '#F8F9FA', height: '100%', overflow: 'auto' }}>
      <Drawer PaperProps={{ sx: { background: 'primary.white', border: 'none' } }} open={isOpen} variant="permanent">
        <DrawerHeader>
          {!isOpen ? (
            <IconButton
              aria-label="open drawer"
              color="inherit"
              edge="start"
              sx={{
                mx: 'auto',
              }}
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          )}
        </DrawerHeader>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            px: 2,
            py: 4,
          }}
        >
          <Avatar alt={user.name} src={user.picture} sx={{ ...iconSize }} />
          <Box sx={{ display: isOpen ? 'block' : 'none' }} textAlign="center">
            <Typography color="#2D3748">{user.name}</Typography>
            <Typography color="#878787" variant="body2">
              {user.roleNames?.join(', ')}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <List>
          {menuItems.map(({ title, icon, link }) => (
            <ListItem
              key={title}
              disablePadding
              selected={menuItem.startsWith(link)}
              sx={{ display: 'block' }}
              title={title}
              onClick={() => {
                navigate(`${Routes.APP}/${link}`);
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: isOpen ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: isOpen ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText primary={title} sx={{ opacity: isOpen ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <List sx={{ mt: 'auto', mb: 0 }}>
          <ListItem
            key="logout"
            disablePadding
            sx={{ display: 'block' }}
            title="Log out"
            onClick={async () => logout()}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: isOpen ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemText primary="Logout" sx={{ opacity: isOpen ? 1 : 0 }} />
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  ml: isOpen ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <Logout />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          pt: 0,
          width: isOpen ? 'calc(100% - 240px)' : 'calc(100% - 64px)',
        }}
      >
        {children}
      </Box>
      <CreateWalletFlow />
    </Box>
  );
}
