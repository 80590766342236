import { useNavigate } from 'react-router-dom';
import { Button, Typography, Paper, Grid, CircularProgress } from '@mui/material';

import { RegistrationStepsRoutes } from '../constants';
import { EApproveLoginOnAppStatus, useApproveLoginOnAppMutation } from '../../redux/api';

import type React from 'react';

const LoginOnAppRejected: React.FC = () => {
  const navigate = useNavigate();
  const [approveLoginOnApp, { isLoading }] = useApproveLoginOnAppMutation();

  const handleApprove = async () => {
    await approveLoginOnApp({ status: EApproveLoginOnAppStatus.Initialize });

    navigate(RegistrationStepsRoutes.APPROVE_LOGIN_ON_APP, { replace: true });
  };

  return (
    <Grid
      container
      sx={{
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'left',
      }}
    >
      <Paper sx={{ p: 3, boxShadow: 'none' }}>
        <Typography
          color="#BA1A1A"
          sx={{
            margin: '16px 0',
          }}
          variant="h5"
        >
          Log in rejected
        </Typography>
        <Typography
          sx={{
            margin: '16px 0 32px',
          }}
          variant="subtitle2"
        >
          User log in rejected on mobile app.
        </Typography>
        <Grid item mt={2} xs={12}>
          <Button
            fullWidth
            disabled={isLoading}
            startIcon={isLoading ? <CircularProgress size="small" /> : undefined}
            sx={{ textTransform: 'none' }}
            variant="contained"
            onClick={handleApprove}
          >
            Log in again
          </Button>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default LoginOnAppRejected;
