import { useNavigate } from 'react-router-dom';

import { Typography, Paper, Grid, CircularProgress } from '@mui/material';

import { useEffect } from 'react';

import { RegistrationStepsRoutes } from '../constants';

import { EApproveLoginOnAppStatus, useGetApproveLoginOnAppStatusQuery } from '../../redux/api';

import type React from 'react';

const ApproveLoginOnApp: React.FC = () => {
  const navigate = useNavigate();
  const { data, isFetching } = useGetApproveLoginOnAppStatusQuery('approveLoginOnAppStatus');

  const status = data?.status;

  const isProcessing = isFetching || status === EApproveLoginOnAppStatus.Initialize;

  useEffect(() => {
    if (isFetching) {
      return;
    }

    if (status === EApproveLoginOnAppStatus.Rejected) {
      navigate(RegistrationStepsRoutes.LOGIN_ON_APP_REJECTED);
    }

    if (status === EApproveLoginOnAppStatus.Approved) {
      navigate(RegistrationStepsRoutes.LOGIN_ON_APP_APPROVED);
    }
  }, [isFetching, navigate, status]);

  return (
    <Grid
      container
      sx={{
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'left',
      }}
    >
      <Paper sx={{ p: 3, boxShadow: 'none' }}>
        <Typography
          sx={{
            margin: '16px 0',
          }}
          variant="h5"
        >
          Approve login on deVaulty app
        </Typography>
        <Typography
          sx={{
            margin: '16px 0',
          }}
          variant="body2"
        >
          You are one step away from the finish line!
        </Typography>
        <Typography
          sx={{
            margin: '16px 0 32px',
          }}
          variant="body2"
        >
          Approve the login request on deVaulty to unleash the power of dMPC.
        </Typography>
        {isProcessing ? (
          <Grid item textAlign="center">
            <CircularProgress />
            <Typography variant="subtitle2">Waiting for approval...</Typography>
          </Grid>
        ) : null}
      </Paper>
    </Grid>
  );
};

export default ApproveLoginOnApp;
